import React from "react"
import * as styles from './styles/video.module.css'

import standardimg from './images/4x3.png'
import wideimg from './images/16x9.png'

const Video = ({ src, title, size }) => (
	<div className={styles.container}>
		<img src={size==="wide"?wideimg:standardimg} alt={title} />
		<iframe
			src={src+"?rel=0"}
			title={title}
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			frameBorder="0"
			webkitallowfullscreen="true"
			mozallowfullscreen="true"
			allowFullScreen
		/>
	</div>
)


export default Video
import React, { Component} from 'react'
import {graphql, Link} from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import ProductList from '../components/ProductList'
import Video from '../components/Video'
import {
	Breadcrumb,
	Container,
	Header,
	Icon,
	Message
} from 'semantic-ui-react'

import * as styles from './styles/template.module.css'
import './styles/blog.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')

export default class BlogPage extends Component {
	state = {
		products: [],
		shown:undefined,
		mobile:undefined,
		loadingProducts:true,
		timeOut1:undefined,
		errorLoadingProducts:false,
	};

	componentDidMount() {
		this.fetchData();
		this.handler();
		window.addEventListener('resize', this.resizeTimer);

		this.addImpressions()

	}

	resizeTimer = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	//adds enhanced e commerce impression for the ingredients list
	addImpressions = () =>{
		const {
			data
		} = this.props
		const { markdownRemark } = data // data.markdownRemark holds your post data
		const { frontmatter } = markdownRemark
		if(frontmatter.ingredientlist) {
			if(frontmatter.ingredientlist.length > 0 ) {
				let dataLayerList = frontmatter.ingredientlist.map((data, index)=>{
					return {
						'name':data[3],
						'id':data[5],
						'price':parseFloat(data[4]),
						'list':`Ingredient List ${frontmatter.canonical}-${frontmatter.slug}`,
						'position':index+1,
						'item_id': data[5],
						'item_name': data[3],
						'currency': "PHP",
						'index': index+1,
						'item_list_id': `Ingredient List ${frontmatter.canonical}-${frontmatter.slug}`,
						'item_list_name': `Ingredient List ${frontmatter.canonical}-${frontmatter.slug}`,
						'quantity': 1
					}
				})
				let filteredList=dataLayerList.filter(({id})=>{
					return id!==""
				})



				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event': 'productList',
					'ecommerce': {
						'impressions': filteredList
					}
				});
			}
		}


	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimer);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				shown: 5,
				mobile:false
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				shown : 5,
				mobile:true
			})
		} else {  //mobile
			this.setState({
				shown : 2,
				mobile:true
			})
		}
	}
	fetchData = () => {
		if(this.props.pageContext.fetchData) {
			Negosyo.getProductSearchBlog(this.props.data.markdownRemark.frontmatter.slug)
			.then((response) => {
				if(Array.isArray(response) ) {
					this.setState({
						products:response,
						loadingProducts:false
					})
				}else {
					this.setState({
						products:[],
						loadingProducts:false
					})
				}
			}).catch((err)=>{
				if(err.status===404) {
					this.setState({
						products:[],
						loadingProducts:false
					})
				}else {
					this.setState({
						loadingProducts:false,
						errorLoadingProducts:true
					})
				}

			})
		}

	}

	render() {
		const {
			products,
			shown,
			mobile,
			loadingProducts,
			errorLoadingProducts
		} =this.state

		const {
			data, // this prop will be injected by the GraphQL query below.
			location,
			pageContext
		} = this.props
		const { markdownRemark } = data // data.markdownRemark holds your post data
		const { frontmatter, html } = markdownRemark
		var datePublished = new Date(frontmatter.date)
		var structuredData = frontmatter.canonical === "recipes"? {
			"@context": "https://schema.org",
			"@type": "Recipe",
			"name": frontmatter.subtitle,
			"datePublished": datePublished.toISOString(),
			"image": [
				frontmatter.thumbnail
			],
			"author": {
				"@type": "Organization",
				"name": "NegosyoNow"
			},
			"recipeIngredient": frontmatter.ingredientmeta,
			"recipeInstructions": frontmatter.directionlist,
			"keywords": frontmatter.recipekeywords,
			"recipeCategory": frontmatter.recipekeywords,
			"description": frontmatter.excerpt
		}: undefined
		//"datePublished": frontmatter.date,
		//"recipeIngredient": [],
		//"recipeInstructions": []
		return (
			<Layout location={location} backButton={true}>
				<SEO title={frontmatter.title}
					canonicalpath={frontmatter.canonical?
										"/"+frontmatter.canonical+"/"+frontmatter.slug
										:
										"/blog/"+frontmatter.slug}
					image={frontmatter.thumbnail} imageWidth={600} imageHeight={400}
					description={frontmatter.excerpt}
					structuredData={structuredData}
					/>
				<Container className={styles.container}>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={pageContext.parenttitle} as={Link} to={"/"+pageContext.parenturl+"/"} />
						<Breadcrumb.Divider />
						{(pageContext.secondaryParentTitle && pageContext.secondaryParentUrl) &&  (
							<React.Fragment>
								<Breadcrumb.Section content={pageContext.secondaryParentTitle} as={Link} to={"/"+pageContext.secondaryParentUrl+"/"} />
								<Breadcrumb.Divider />
							</React.Fragment>
						)}
						<Breadcrumb.Section content={frontmatter.title} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						{(pageContext.secondaryParentTitle && pageContext.secondaryParentUrl) ?  (
							<Breadcrumb.Section as={Link} to={"/"+pageContext.secondaryParentUrl+"/"} >
								<Icon name="arrow left" />
								{pageContext.secondaryParentTitle}
							</Breadcrumb.Section>
						):(
							<Breadcrumb.Section as={Link} to={"/"+pageContext.parenturl+"/"} >
								<Icon name="arrow left" />
								{pageContext.parenttitle}
							</Breadcrumb.Section>
						)}
					</Breadcrumb>
					<Header className={`${styles.centerText} ${styles.superHeaderMod}`}>
						<h1>{frontmatter.title}</h1>
						{frontmatter.subtitle && <div className={`${styles.centerText} blog-subtitle`}>{frontmatter.subtitle}</div>}
						<Header.Subheader>
						{frontmatter.date}
						</Header.Subheader>
					</Header>
					<div className={styles.item+" blog-content"}
						dangerouslySetInnerHTML={{ __html: html===undefined || html==="" ? frontmatter.html:html}}
					/>
					{	frontmatter.ingredientlist &&
						<>
							<hr className="blog-section-divider"/>
							<h2>Ingredients</h2>
							<ul className="blog-content blog-ingredient">
							{frontmatter.ingredientlist.map((data, index) => {
								return <li key={data[1]}>
										<span>{data[0]}</span> {data[1]}
										{data[2] === ""
											? ""
											:(
												<Link to={`/${data[2]}/`} onClick={()=>{
													window.dataLayer = window.dataLayer || [];
													window.dataLayer.push({
														'event':'productClick',
														'ecommerce': {
															'click': {
																'actionField': {'list':`Ingredient List ${frontmatter.canonical}-${frontmatter.slug}`},
																'products': [{
																	'name':data[3],
																	'id':data[5],
																	'price':parseFloat(data[4]),
																	'position':index+1,
																	'item_id': data[5],
																	'item_name': data[3],
																	'currency': "PHP",
																	'index': index+1,
																	'item_list_id': `Ingredient List ${frontmatter.canonical}-${frontmatter.slug}`,
																	'item_list_name': `Ingredient List ${frontmatter.canonical}-${frontmatter.slug}`,
																	'quantity': 1
																}]
															}
														}
													});
												}}>
													&nbsp;&nbsp;<Icon className="blog-carticon" name="cart"/>
												</Link>
											)
										}
									</li>
							})}
							</ul>
						</>
					}
					{	frontmatter.videolist &&
						<>
							<hr className="blog-section-divider"/>
							<h2>Watch Videos</h2>
							{frontmatter.videolist.map((data, index) => {
								return <React.Fragment key={data[0]} itemscope="" itemprop="VideoObject" itemtype="https://schema.org/VideoObject">
										<div style={{display:"none"}}>
											<div itemprop="uploadDate">{frontmatter.date}</div>
											<div itemprop="embedUrl">{data[0]}</div>
										</div>
										<h3 itemprop="name">{data[2]}</h3>
										<Video src={data[0]} title={data[2]} size={data[1]} />
									</React.Fragment>
							})}
						</>
					}
					{	frontmatter.directionlist &&
						<>
							<hr className="blog-section-divider"/>
							<h2>Directions</h2>
							<ol className="blog-content blog-direction" >
							{frontmatter.directionlist.map((data, index) => {
								return <React.Fragment key={index}>
										<li className="blog-direction-step">
											{data}
											{frontmatter.directionbodylist[index][0] !== "" && (
												<ul>
													{frontmatter.directionbodylist[index].map((datasub, indexsub) => {
														return <li key={indexsub}>
																{datasub}
															</li>
													})}
												</ul>
											)}
											{frontmatter.directionnotelist[index] !== "" && (
												<div>
													{frontmatter.directionnotelist[index]}
												</div>
											)}
										</li>
									</React.Fragment>
							})}
							</ol>
						</>
					}
					{frontmatter.footer && (
						<p
							className={"blog-content"}
							dangerouslySetInnerHTML={{ __html: frontmatter.footer}}
						/>
					)}
					{errorLoadingProducts===false ? (
						<React.Fragment>
							{ !loadingProducts  && (
								<React.Fragment>
									{products.length>0 && (
										<>
											<hr className="blog-section-divider"/>
											<h2>Shop for Ingredients and Supplies</h2>
											<ProductList
												products={products}
												shown={shown}
												grid={(mobile===false) ? 12:16}
												cameFrom={`${frontmatter.canonical}-${frontmatter.slug}`}
												page={1}
												listName={`${frontmatter.canonical}-${frontmatter.slug}`}
											/>
										</>
									)}
								</React.Fragment>
							)}
						</React.Fragment>
					):(
						<Message negative className={styles.centerText}>
							<Message.Header>
							Unable to get the products. Please refresh the page
							</Message.Header>
						</Message>
					)}

				</Container>
			</Layout>
		)
	}
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        footer
        thumbnail
        canonical
        excerpt
        recipekeywords
        ingredientlist
        ingredientmeta
        directionlist
        directionbodylist
        directionnotelist
        videolist
      }
    }
  }
`